import { tns } from 'tiny-slider/src/tiny-slider';

import 'jquery.marquee';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
  function eventFire(el, etype) {
    if (el.fireEvent) {
      el.fireEvent('on' + etype);
    } else {
      var evObj = document.createEvent('Events');
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  }
window.addEventListener('DOMContentLoaded', (event) => {
  // CARROUSEL KIOSQUE

  var carousel = document.getElementById('carousel-container');
  if (carousel !== null) {
    var carousels = tns({
      container: '.kiosque-content-container',
      items: 1,
      slideby: 'page',
      nav: false,
      lazyload: true,
      controlsContainer: '#customize-controls',
    });
  }

// SEARCH BUTTON
let searchInputMobile = false;
jQuery('#search-button-mobile').on('click', function () {
  if(searchInputMobile)
    {
      jQuery('#top-search-bar').css('display', 'none');
      searchInputMobile = false;
      console.log('searchInputMobile: ', searchInputMobile);
    }
  else
    {
      jQuery('#top-search-bar').css('display', 'flex');
      searchInputMobile = true;
      console.log('searchInputMobile: ', searchInputMobile);
    }
});

  //FLASH INFOS

  jQuery('.marquee').marquee({
    duration: 10000,
    gap: 30,
    delayBeforeStart: 0,
    direction: 'left',
    duplicated: false,
    pauseOnHover: true,
    startVisible: false,
  });

  // MES DEMARCHES
  var arrowToDisplay = jQuery('.arrow');
  arrowToDisplay.on('click', function () {
    jQuery('#container-open').toggleClass('visible');
  })


  // MENU DE NAVIGATION

  var menuPrincipal = document.getElementById('primary-menu');
  var li = document.createElement('li');
  var img = document.createElement('img');
  var a = document.createElement('a');

  img.setAttribute(
    'src',
    '/wp-content/uploads/picto-png/menu-principal/accueil_2022-02-25/accueil@3x.png'
  );
  img.setAttribute('id', 'img-home');
  img.classList.add('img-menu-principal');
console.log(window.origin)
  a.setAttribute('href', window.origin);
  a.appendChild(img);
  console.log(a);
  li.appendChild(a);
  menuPrincipal.prepend(li);
  function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}
    jQuery('#access-link').on('click', function () {
      eventFire(document.getElementById('uci_link'), 'click');
    });
});

